<template>
  <footer>
    <div class="footer-content">
      <p>E-mail: <a href="mailto:contact@vidaviewtech.com">contact@vidaviewtech.com</a></p>
      <p>Copyright © 2024 Vidaview Tech AB</p>
    </div>
  </footer>
</template>
  
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
  },
  components: {
  },
})
export default class Footer extends Vue {
}
</script>

<style scoped>
footer {
  background-color: black;
  color: var(--text-light);
  padding: 20px;
  height: 60px;
}

a:link {
  color: var(--text-light);
  background-color: transparent;
  text-decoration: underline;
}

.desktop-view .footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-view .footer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-view p {
  font-size: smaller;
  margin: 7px;
}

</style>
