<template>
  <div class="home">
    <h1 class="title">Revolutionize your orienteering experience with <span class="app-name">GPS Orienteering</span></h1>
    <a class="gpso-link" href="https://gpso.se">
        <button class="prominent-button">Try it now!</button>
    </a>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
  },
  components: {
  },
})
export default class HomeView extends Vue {
}
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 32px;
  font-weight: normal;
  text-align: center;
}

.app-name {
  font-size: 34px;
  text-shadow: 5px 5px 10px rgba(41, 57, 57, 0.5);
}

.gpso-link {
  margin-top: 30px;
}

.prominent-button {
  font-size: 24px;
  font-weight: bold;
  font-family: 'TenorSans';
  color: var(--text-light);
  background-color: var(--secondary-color);
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
  padding: 16px 32px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.prominent-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

.desktop-view 
.title {
  padding-top: 150px;
}

.desktop-view 
.prominent-button {
  width: 400px;
}

.mobile-view
.title {
  padding-top: 130px;
}

.mobile-view
.prominent-button {
  width: 300px;
}
</style>
