<template>
  <div class="body" v-bind:class="{ 'desktop-view': !isMobile, 'mobile-view': isMobile }" >
    <dib class="gradient"/>
    <div class="header">
      <div class="horizontal">
        <img class="logo" alt="Vidaview logo" src="./assets/logo_dark.png">
        <p class="name">VIDAVIEW TECH</p>
      </div>
    </div>
    <div class="content">
      <HomeView/>
    </div>
    <Footer/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import HomeView from './views/HomeView.vue'
import Footer from './components/Footer.vue'

@Options({
  components: {
    HomeView,
    Footer
  }
})
export default class App extends Vue {

  isMobile = false

  created() {
    this.isMobile = window.innerWidth <= 768
  }

}
</script>

<style>
:root {
  --highlight-color: #FF8300;
  --primary-color: #6d8e40;
  --secondary-color: #293939;
  --background-color: #4c5d51;
  --background-deep-color: #365942;
  --text-dark: #16171b;
  --text-light: #e4f4ff;
}

@font-face {
  font-family: 'TenorSans';
  src: url('./assets/tenor_sans/TenorSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

#app {
  font-family: TenorSans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-light);
}

body {
  background-color: var(--background-color);
  margin: 0px;
}

.gradient {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 50vh;
  background-image: linear-gradient(to bottom, var(--background-deep-color), transparent);
  background-repeat: no-repeat;
  opacity: 0.65;
}

.horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>

<style scoped>
.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.header {
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-dark);
}

.logo {
  padding-left: 15px;
  height: 80px;
}
.name {
  padding-left: 15px;
  font-weight: bolder;
  letter-spacing: 10px;
  font-size: 28px;
}

.mobile-view .header {
  height: 70px;
  justify-content: center;
}

.mobile-view .logo {
  padding-left: 0px;
  height: 60px;
}

.mobile-view .name {
  font-size: 20px;
}
</style>
